.header {
  background: linear-gradient(to bottom, rgb(0, 110, 165), rgb(88, 180, 225));
  padding: 15px;
  text-align: center;
  border-bottom-right-radius: 33px;
  box-shadow: 0 0 25px 0 rgba(0,0,0,0.31);
  z-index: 1000 !important;
  position: relative;
}

h1 {
  color: #FFF;
  font-weight: 600;
}

form {
  padding: 0;
  margin: 0;
}

.wrapper {
  margin: auto;
  width: 1100px;
  text-align: left;
}

.heroImage {
  position: relative;
  margin-top: -33px;
  overflow: hidden;
  border-bottom-right-radius: 33px;
  z-index: 1 !important;
  box-shadow: 0 0 25px 0 rgba(0,0,0,0.31);
  padding: 0;
}

.heroImage img {
  width: 100%;
  object-fit: contain;
  z-index: 2;
}

.primaryButton {
  background: #b4d959;
  border-radius: 20px;
  font-weight: 600;
  color: #FFF;
  padding: 10px 30px;
  border: none;
  font-family: "Dosis", "Open Sans", serif;
  margin-bottom: 50px;
  margin-top: 30px;
  display: block;
  font-size: 16px;
}

h2 {
  font-size: 25px;
  font-weight: 600;
  color: #1d83b5;
  width: 100%;
}

h3 {
  font-size: 25px;
  font-weight: 600;
  color: #abce42;
}

span {
  color: rgb(26, 131, 183);
  font-size: 25px;
}

label {
  color: #707070;
  font-size: 25px;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

input {
  border-radius: 20px;
  background: rgba(88, 180, 225, 0.15);
  border: solid .5px #1a83b7;
  padding: 5px 10px;
  font-size: 16px;
  font-family: "Dosis", "Open Sans", serif;
}

.secondaryButton {
  background: #1a83b7;
  border-radius: 20px;
  font-weight: 600;
  color: #FFF;
  padding: 5px 10px;
  border: none;
  font-family: "Dosis", "Open Sans", serif;
  text-align: right;
  font-size: 16px;
}

.primaryContainer {
  margin-top: -33px;
  padding: 60px 0;
  display: flex;
  flex-direction: column;
  background: rgb(243, 243, 243);
}

.primaryContainer label {
  max-width: 1000px;

}

.primaryContainer input {
  flex: 1;
  width: 400px;
  margin-left: 30px;
}

.familyContainer {
  display: flex;
  flex-direction: column;
  justify-content: end;
}

.familyContainer label {
  /*flex-direction: column;*/
  display: inline;
}

.familyContainer input {
  display: block;
  width: 350px;
  padding: 5px 10px;
  margin-top: 10px;
  margin-bottom: 15px;
}

.messageContainer {
  padding: 30px;
  position: absolute;
  top: 150px;
  border-bottom-right-radius: 40px;
  border-bottom-left-radius: 40px;
  border-top-right-radius: 40px;
  color: rgb(29, 131, 181);
  background: rgba(255,255,255,0.7);
  font-size: 44px;
  font-weight: bold;
  box-shadow: 0 0 25px 0 rgba(0,0,0,0.31);
}

.green {
  color: rgb(179, 216, 89);
  font-size: 44px;
  font-weight: bold;
}

.left {
  flex: 1;
  width: 100%;
  margin-right: 10px;
}

.right {
  flex: 1;
  justify-content: flex-end;
}

.right img {
  width: 700px;
  margin-right: -50px;
  margin-top: 15px;
  bottom:0;
}

.responseContainer {
  position: absolute;
  top: 100px;
  padding: 50px 40px;
  background: #FFF;
  border-radius: 33px;
  margin-top: -15px;
  color: rgb(29, 131, 181);
  background: rgba(255,255,255,0.7);
  font-size: 44px;
  font-weight: bold;
  box-shadow: 0 0 25px 0 rgba(0,0,0,0.31);
  width: 350px;
  margin-bottom: 50px;
}

.rowFlex {
  display: flex;
  flex-direction: row;
}

.validation {
  font-size: 16px;
  color: red;
}

@media (max-width: 1000px) {

  .wrapper {
    width: auto;
    margin: 0 10px;
  }

  .header {
    font-size: 12px;
  }

  .heroImage {
    min-height: 200px;
  }

  .heroImage img {
    min-height: 200px;
    object-fit: cover;
  }

  .messageContainer {
    top: 65px;
    right: 10px;
    font-size: 16px;
    background-color: rgba(255,255,255,0.8);
  }

  .green {
    font-size: 16px;
  }

  h2 {
    font-size: 18px;
  }

  h3 {
    font-size: 18px;
  }

  .primaryContainer {
    font-size: 16px;
  }

  .primaryContainer label {
    font-size: 16px;
    display: inline;
  }

  .primaryContainer input {
    display: block;
    width: 80%;
    margin: 0;
  }

  .familyContainer {
    font-size: 16px;
  }

  .familyContainer label {
    font-size: 16px;
    display: inline;
  }

  .familyContainer input {
    display: block;
    width: 80%;
    margin: 0;
  }

  .rowFlex {
    flex-direction: column;
  }

  .right img {
    width: 100%;
  }

  .secondaryButton {
    margin-top: 10px;
    font-size: 16px;
  }

  .responseContainer {
    top: 65px;
    /*right: 10px;*/
    font-size: 16px;
    width: auto;
    padding: 20px;
    background-color: rgba(255,255,255,0.8);
  }
}
