.container {
  position: relative;
  margin-top: 10px;
  margin-bottom: 10px;
}

.valueContainer {
  border-radius: 20px;
  padding: 5px 10px;
  background: rgb(88 180 225);
  border: 0.5px solid rgb(26, 131, 183);
  display: flex;
  justify-content: space-between;
  color: white;
  align-items: center;
  z-index: 5;
  position: relative;
}

.container span {
  font-size: 16px;
  color: #FFF;
}

.container img {
  width: 20px;
}

.valuesContainer {
  position:absolute;
  top: -15px;
  background: #FFF;
  list-style: none;
  padding: 30px 0;
  box-shadow: 0 0 5px 0 rgba(0,0,0,0.31);
  margin: 30px 0;
  border-bottom-right-radius: 20px;
  border-bottom-left-radius: 20px;
  overflow: hidden;
  z-index: 3;
}

.valuesContainer li {
  display: block;
  background: rgb(230, 244, 251);
  margin-bottom: 10px;
  color: #1a83b7;
  padding: 5px 10px;
  font-size: 16px;
}