.container {
  color: #707070;
  font-size: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 15px;
  justify-items: baseline;
}

.selectedState {
  width: 10px;
  height: 10px;
  background: black;
  border-radius: 5px;
}

.unselectedState {
  width: 20px;
  height: 20px;
  flex-shrink: 0;
  flex-grow: 0;
  border-radius: 10px;
  background: rgba(88, 180, 225, 0.15);
  border: solid 0.5px #1a83b7;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.container p {
  display: inline;
  margin: 0;
}

@media (max-width: 1000px) {
  .container {
    margin-top: 10px;
  }

  .container p {
    font-size: 16px;
  }
}
